<template>
  <div>
    <div class="row justify-content-md-center" v-cloak v-if="!state.loaded">
      <div class="col col-lg-2"></div>
      <div class="col-md-auto mt-20">
        <rotate-square5></rotate-square5>
      </div>
      <div class="col col-lg-2"></div>
    </div>
    <div class="card" v-else>
      <div class="card-body">
        <form @submit.prevent="submit" class="form-horizontal">
          <div class="form-body">
            <h3 class="box-title">Klasifikasi Surat Management</h3>
            <hr class="m-t-0 m-b-40">
            <div class="row">
              <div class="col-md-8">
                <Input v-model="payload.name" name="nama" required type="text" label="Nama"/>
              </div>
            </div>
            <div class="row">
              <div class="col-md-8">
                <Input v-model="payload.code" name="code" required type="text" label="Code"/>
              </div>
            </div>
            <div class="row">
              <div class="col-md-8">
                <div class="form-group row">
                  <label :class="['control-label', {'text-right': !isMobile}, 'col-md-3']">
                    Jenis Surat
                    <small style="color:red">*</small>
                  </label>
                  <div :class="['col-md-9',{ 'invalid': isInvalidType }]">
                    <Multiselect
                      v-model="payload.type"
                      label="text"
                      track-by="value"
                      placeholder="Type to search"
                      open-direction="bottom"
                      :options="listTypes"
                      :multiple="false"
                      :searchable="true"
                      :internal-search="false"
                      :clear-on-select="true"
                      :close-on-select="false"
                      :options-limit="300"
                      :max-height="600"
                      :show-no-results="true"
                      :hide-selected="true"
                      @open="listFind('')"
                      @search-change="listFind"
                    >
                      <span slot="noResult">Oops! Jenis Surat tidak ditemukan.</span>
                    </Multiselect>
                    <label
                      class="typo__label form__label"
                      v-show="isInvalidType"
                    >Must have at least one value</label>
                  </div>
                </div>
              </div>
            </div>
            <div class="row ">
							<div class="col-md-8">
								<div class="form-group">
									<label class="col-md-12">Format Surat <span v-if="payload.file">: format sudah tersedia, silahkan <a class="btn btn-success" :href="state.detail == null ? '#' : state.detail.link_file" target="_blank"><i class="fa fa-download"></i> klik di sini</a> untuk mengunduh format saat ini.</span></label>
									<drop-file-docx @data="assignFileDocx"></drop-file-docx>
								</div>
							</div>
						</div>
          </div>
          <div class="row">
              <div class="col-md-8">
                <div class="form-group row">
                  <label :class="['control-label', {'text-right': !isMobile}, 'col-md-3']">Aktif</label>
                  <div class="col-md-9 col-sm-9">
                    <div class="form-check">
                      <label class="form-check-label">
                        <input
                          v-model="payload.is_draftable"
                          type="radio"
                          value="1"
                          class="form-check-input"
                          name="optradio"
                        >Ya
                      </label>
                    </div>
                    <div class="form-check">
                      <label class="form-check-label">
                        <input
                          v-model="payload.is_draftable"
                          type="radio"
                          value="0"
                          class="form-check-input"
                          name="optradio"
                        >Tidak
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          <hr>
          <div class="form-actions">
            <div class="row">
              <div class="col-md-6">
                <div class="row">
                  <div class="col-md-offset-3 col-md-9">
                    <button type="submit" class="btn btn-success" title="Submit">Submit</button>&nbsp;
                    <button type="button" @click="goBack()" class="btn btn-inverse" title="Cancel">Cancel</button>
                  </div>
                </div>
              </div>
              <div class="col-md-6"></div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { RotateSquare5 } from "vue-loading-spinner";
import Input from "@/components/input/input";
import axios from "axios";
import Multiselect from "vue-multiselect";

export default {
  components: {
    Input,
    Multiselect,
    RotateSquare5,
  },
  computed: {
    isInvalidType() {
      return this.payload.type == null ||  this.payload.type == undefined;
    },
    editMode() {
      return !!this.$route.params.id;
    },
    state() {
      return this.$store.state.clasification;
    },
    isMobile() {
      return this.$store.state.isMobile;
    }
  },
  data() {
    return {
      payload: {
        name: "",
        code: "",
        file: "",
        type: null,
        is_draftable:0
      },
      listTypes:[]
    };
  },
  async mounted() {
    this.initialize();
  },

  methods: {
    listFind(query){
      axios.get(`letter-type?s=${query}`).then(data=>{
        this.listTypes = data.data.items
      })
    },
    assignFileDocx(docx){
			this.payload.file = docx;
		},
    async initialize() {
      let state = {
        loaded: false,
        detail:null
      };
      this.$store.commit("clasification/STATE", state);
      try {
        if (this.editMode) {
          axios
            .get(`/clasification/${this.$route.params.id}`)
            .then(response => {
              state = {
                loaded: true,
                detail:response.data.data
              };
              this.$store.commit("clasification/STATE", state);
              this.edit(response.data.data);
            });
        } else {
          this.$store.dispatch("clasification/onAdd");
        }
      } catch (e) {
        this.handleApiError(e);
        this.error = e.message;
      }
    },
    edit(data) {
      this.payload = {
        name: data.name,
        code: data.code,
        file: data.file,
        type: data.type,
        is_draftable:data.is_draftable
      };
    },
    goBack() {
      this.$store.dispatch("clasification/onCancel");
    },
    submit() {
      const data = JSON.stringify(this.payload);
      let formData = new FormData();
      for(let i in this.payload){
        if(i == 'type'){
          formData.set(i,this.payload[i].value);        
        }else{
          formData.set(i,this.payload[i]);        
        }
      }
      if (this.editMode) {
        formData.set('id',this.$route.params.id);
      }
      this.$validator.validateAll().then(success => {
        if (success) {
          if (this.editMode) {
            this.$store.dispatch("clasification/submitEdit", {
              formData: formData,
              id: this.$route.params.id
            });
          } else {
            this.$store.dispatch("clasification/submitAdd", formData);
          }
        }
      });
    }
  }
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.multiselect__tags {
  border: 1px solid #ced4da !important;
}
.invalid .multiselect__tags {
  border-color: #f04124 !important;
  padding: 5px 40px 0 8px !important;
}
.invalid .typo__label {
  color: #f04124;
  font-size: 0.8125rem;
}
.multiselect {
  color: #54667a !important;
}
.multiselect__select {
  height: 41px !important;
}
</style>

